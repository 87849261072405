export const fsmask = 'fs-mask';
export const excludeStatesToEdit = ['CT', 'FL', 'CO'];
export const petInsuranceBaseUrl = 'https://quote.embracepetinsurance.com/';
export const marketingWebUrl = 'https://homebody.com';
export const marketingWebName = 'Homebody';
export const pageLimit = 10;
export const QUOTE_VERSION_V2 = 'v2';
export const QUOTE_VERSION_V3 = 'v3';
export const DATE_FORMAT_EN_US = 'MM/DD/YYYY';

export function getProductsUrl() {
  const rentersInsuranceUrl = `${marketingWebUrl}/products/renters-insurance`;
  const idTheftProtectionUrl = `${marketingWebUrl}/products/id-theft`;
  const petInsuranceUrl = `${marketingWebUrl}/products/pet-insurance`;

  return { rentersInsuranceUrl, idTheftProtectionUrl, petInsuranceUrl };
}

export function getFooterUrls() {
  const legalUrl = `${marketingWebUrl}/legal/disclosures`;
  const termsUrl = `${marketingWebUrl}/legal/terms-conditions`;
  const aboutUrl = `${marketingWebUrl}/about`;
  const faqUrl = `${marketingWebUrl}/faq`;
  const privacyPolicyUrl = `${marketingWebUrl}/legal/privacy-policy`;

  return { legalUrl, termsUrl, aboutUrl, faqUrl, privacyPolicyUrl };
}

export function showLinks() {
  let showLink = true;
  if (typeof window !== 'undefined' && window.location) {
    const { pathname } = window.location;
    showLink = !pathname.includes('enroll') && !pathname.includes('get-quote') && !pathname.includes('add');
  }

  return showLink;
}

export function getAppLink(system) {
  if (system === 'ios') {
    return 'https://apps.apple.com/us/app/homebody-better-renting/id6451412538';
  }

  return 'https://play.google.com/store/apps/details?id=com.homebody&pli=1';
}

export function getQuoteVersion(employeeId = null) {
  // Will un-comment code once we have confirmation on the quote version v3
  // if (employeeId) {
  //   return QUOTE_VERSION_V3;
  // }

  if (typeof window !== 'undefined' && window?.quoteVersion) {
    return window.quoteVersion;
  }

  return QUOTE_VERSION_V2;
}
